import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import arrow from "../../../assets/images/arrow-back.svg";

import "./curiosityCard.scss";

export const CuriosityCard = ({ curiosity, onClick }) => {
    const hasImage =
        curiosity.images[0].localFile &&
        curiosity.images[0].localFile.publicURL.indexOf("placeholder") === -1;

    return (
        <>
            <div
                className={`curiosity-card ${hasImage ? "" : "curiosity-card__no-image"
                    }`}
                onClick={onClick}
            >
                <div className="curiosity-card--content">
                    <h3 className="h2 curiosity-card--name font-weight-normal">
                        {curiosity.name}
                    </h3>
                    <Paragraph className="curiosity-card--description">
                        {curiosity.description}
                        <button
                            className="curiosity-card--description-arrow"
                            aria-label="Przeczytaj więcej o ciekawostce"
                        >
                            <img src={arrow} alt="" />
                        </button>
                    </Paragraph>
                </div>
                <div className="curiosity-card--background-image">
                    <GatsbyImage
                        image={getImage(curiosity.images[0].localFile)}
                        alt={curiosity.name}
                    />
                </div>
            </div>
        </>
    );
};

export default CuriosityCard;
